<template>
    <section class="mycard" id="mycard">
      <div class="container">
        <div class="sTitle">Ваша sustainable-card готова!</div>
        <div class="raw">
          <div class="col-1">
            <a class="button light full"  v-on:click="openModalShare=true">Поделиться <div class="share-two"></div></a>
            <a class="button light" v-on:click="openModalDownload=true">Скачать  <div class="to-download"></div></a>
            <div class="delimiter"></div>
            <a class="button light" v-on:click="$router.push('/edit/'+$route.params.id)">Редактировать  <div class="edit-two"></div></a>
            <a class="button " v-on:click="editLinkCopy">Сохранить ссылку  <div class="copy-link"></div></a>
            <div class="boxRec">
              <div class="iconCol"><div class="icon"></div></div>
              <div class="textCol">Сохраните ссылку, чтобы вносить изменения в&nbsp;дальнейшем</div>
            </div>
          </div>
          <div class="col-2">
            <div class="qrBox">
              <img :src="card.qrcode">
            </div>
            <div class="qrOpenLink">
              <a v-on:click="openModalCard=true">Посмотреть визитку</a>
            </div>
          </div>
        </div>
      </div>
      <div class="modalDownload" v-if="openModalDownload" >
        <div class="shadow" v-on:click="closeModalDownload"></div>
        <div class="modalContainer">
          <div class="header">
            Скачать
            <div class="close" v-on:click="closeModalDownload"></div>
          </div>
          <div class="links">
            <div class="link">
              <div class="title">QR-код</div>
              <div class="download">
                <a target="_blank" :href="downloadLink">СКАЧАТЬ</a>
              </div>
            </div>
            <div class="link">
              <div class="title">Визитку</div>
              <div class="download">
                <a target="_blank" :href="downloadCardLink">СКАЧАТЬ</a>
              </div>
            </div>
            <div class="link">
              <div class="title">Заставку</div>
              <div class="download">
                <a target="_blank" :href="downloadHeadbandLink">СКАЧАТЬ</a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="modalShare" v-if="openModalShare" >
        <div class="shadow" v-on:click="closeModalShare"></div>
        <div class="modalContainer">
          <div class="header">
            Поделиться
            <div class="close" v-on:click="closeModalShare"></div>
          </div>
          <div class="links">
            <a class="link" :href="'https://telegram.me/share/url?url='+qrLink">
              <div class="icon">
                <img src="@/assets/images/Telegram.png">
              </div>
              <div class="title">Telegram</div>
            </a>
            <a class="link" :href="'https://vk.com/share.php?url='+qrLink">
              <div class="icon">
                <img src="@/assets/images/VK.png">
              </div>
              <div class="title">Вконтакте</div>
            </a>
            <a class="link" :href="'whatsapp://send?text='+qrLink">
              <div class="icon">
                <img src="@/assets/images/WhatsApp.png">
              </div>
              <div class="title">WhatsApp</div>
            </a>
            <a class="link" :href="'mailto:?subject=Моя digital-визитка&body='+qrLink">
              <div class="icon">
                <img src="@/assets/images/Mail.png">
              </div>
              <div class="title">Почта</div>
            </a>

          </div>
          <div class="baseLink">
            <div class="textLink">{{qrLink}}</div>
            <div class="copyLink" v-on:click="cardLinkCopy"><a>Копировать</a></div>
          </div>
        </div>
      </div>
      <div class="modalCard" v-if="openModalCard">
        <div class="shadow" v-on:click="openModalCard=false"></div>
        <div class="closeBox">
          <a v-on:click="openModalCard=false"></a>
        </div>
        <card/>
      </div>
    </section>
</template>
<script>
import Card from './Card.vue'
import axios from 'axios';
axios.defaults.withCredentials = false;

//https://sc.vorobev.pro/api/card/download/01G2N9ZVYJ8ETG1ZYJ9T40YEX7
//https://sc.vorobev.pro/api/card/get/01G2N811PT77DTTAQ1XW7S6B42
//https://sc.vorobev.pro/api/card/vcf/01G2N811PT77DTTAQ1XW7S6B42

//this.$route.params.id
export default {
  name: 'MyCard',
  components: {
    card:Card
  },
  data(){return {
    openModalDownload: false,
    openModalShare: false,
    openModalCard: false,
    downloadLink:'',
    downloadHeadbandLink:'',
    downloadCardLink:'',
    editLink:'',
    qrLink:'',
    card: {
        id: '',
        code: '',
        name: '',
        org: '',
        position: '',
        phone: '',
        email: '',
        site: '',
        photo_original: '',
        photo_link: '',
        facebook: '',
        color_bg: '',
        color_text: '',
        qrcode:'',
        datetime: 0,
    }
  }},
  beforeCreate : async function () {
    if(!this.$route.params.id || this.$route.params.id.length<26) return this.$router.push('/404');
    try{
      const result = await axios.get((process.env.NODE_ENV === "production" ? "." : "https://sc.vorobev.pro" ) + "/api/card/getBySK/"+this.$route.params.id, {withCredentials: false});
      if(!result.data.success) return this.$router.push('/404');
      this.card = result.data.card;
      this.downloadLink = (process.env.NODE_ENV === "production" ? "." : "https://sc.vorobev.pro" ) + '/api/card/download/'+result.data.card.id;
      this.downloadHeadbandLink = (process.env.NODE_ENV === "production" ? "." : "https://sc.vorobev.pro" ) + '/api/card/download/headband/'+result.data.card.id;
      this.downloadCardLink = (process.env.NODE_ENV === "production" ? "." : "https://sc.vorobev.pro" ) + '/api/card/download/card/'+result.data.card.id;
      var base_url = window.location.origin;
      this.editLink = base_url+'/mycard/'+this.$route.params.id+'#mycard';
      this.qrLink = base_url+'/card/'+result.data.card.id+'/'+result.data.card.code;
    }catch(e){
      alert('Internal error');
      // return this.$router.push('/404');
    }
  },
  methods: {
    mouseOver: function(n){
        this.activeId = n;   
    },
    editLinkCopy:function(){ 
      var textarea = document.createElement("textarea");
      textarea.classList.add('hEditLink');
      textarea.textContent = this.editLink;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    },
    cardLinkCopy:function(){ 
      var textarea = document.createElement("textarea");
      textarea.classList.add('hEditLink');
      textarea.textContent = this.qrLink;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    },
    handleScroll: function (evt, el) {
      if(store.page!='home')return;
      var topView= document.querySelector('.view3').offsetTop;
      var hView= document.querySelector('.view3').offsetHeight;
      if(window.scrollY>topView&&window.scrollY<(topView+hView-(hView/10))&&store.modals.current==''){
        document.querySelector('header').classList.add("hide");
      }else{
        document.querySelector('header').classList.remove("hide");
      }
    },
    scrollTo:function(id){
      if(store.modals.current!='')store.modals.current='';
      //console.log('test')
      var elTop=document.getElementById(id).offsetTop;
      //console.log('test',elTop)
      store.workflowHide=true;
      window.scrollTo({top: elTop,left:0,behavior: "smooth"});
      if(this.setTimeoutid>0)clearTimeout(this.setTimeoutid);
      this.setTimeoutid=setTimeout(() => {
        store.workflowHide=false;
      }, 1500);
    },
    closeModalDownload:function($event){
      this.openModalDownload=false;
    },
    closeModalShare:function($event){
      this.openModalShare=false;
    },
  },
  mounted(){
    // setTimeout(()=>{
    //   this.frameMenuOn=true;
    //   this.newInterval();
    // },1500);
  },
}
</script>
