import '@/assets/css/styles.scss';
import '@/assets/css/main.scss';
// import Vue from 'vue'
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import Vue2TouchEvents from 'vue2-touch-events'
import router from './router'
// import { VueHammer } from 'vue2-hammer'
axios.defaults.withCredentials = true
Vue.use(Vue2TouchEvents)

Vue.config.productionTip = false
// Vue.directive('scroll', {
//   inserted: function (el, binding) {
//     let f = function (evt) {
//       if (binding.value(evt, el)) {
//         window.removeEventListener('scroll', f)
//       }
//     }
//     window.addEventListener('scroll', f)
//   }
// })
// Vue.use(VueHammer)

var bootstrap=async function(){
  new Vue({
    router,
    render: h => h(App),
  }).$mount('#app')
}
bootstrap();
