<template>
    <div class="input color-selector">
      <!-- <input class="input" type="text" placeholder="Цвет текста" v-on:input="onInput" v-model="color"> -->
      <div class="title">
        <div>{{title}}</div>
        <div :class="{'colorIndicator':true, 'border': colorsBordered.includes(color)}" :style="{'background':color}"></div>
        
      </div>
      <div class="tools">
        <div class="open" v-on:click="openPanel"></div>
      </div>
      <div class="colorPanel" v-if="isOpenColorPanel">
        <div class="title">
          <div>{{title}}</div>
        </div>
        <div class="tools">
          <div class="close" v-on:click="closePanel"></div>
        </div>
        <div class="colorsList">
          <div v-for="item in colors" :class="{'colorItem':true, 'border': colorsBordered.includes(item), 'selected': item==color, 'white': !colorsBlackSelect.includes(item),}" :key="item" :style="{'background':item}" v-on:click="setColor(item)"></div>
        </div>
      </div>
      <div class="shadow" v-if="isOpenColorPanel" v-on:click="closePanel"></div>
    </div>
</template>
<script>
export default {
  name: 'color-selector',
  props: {
    'title':{
      type: String
    },
    'value':{
      type: String
    }
  },
  data(){return {
    isOpenColorPanel: false,
    color: this.value,
    colors:[
      '#000000','#434343','#666666','#999999','#B7B7B7','#CCCCCC','#D9D9D9','#EFEFEF','#F3F3F3','#FFFFFF',
      '#980000','#FF0000','#FF9900','#FFFF00','#00FF00','#00FFFF','#4A86E8','#0000FF','#9900FF','#FF00FF',
      '#E6B8AF','#F4CCCC','#FCE5CD','#FFF2CC','#D9EAD3','#D0E0E3','#C9DAF8','#CFE2F3','#D9D2E9','#EAD1DC',
      '#DD7E6B','#EA9999','#F9CB9C','#FFE599','#B6D7A8','#A2C4C9','#A4C2F4','#9FC5E8','#B4A7D6','#D5A6BD',
      '#CC4125','#E06666','#F6B26B','#FFD966','#93C47D','#76A5AF','#6D9EEB','#6FA8DC','#8E7CC3','#C27BA0',
      '#A61C00','#CC0000','#E69138','#F1C232','#6AA84F','#45818E','#3C78D8','#3D85C6','#674EA7','#A64D79',
      '#85200C','#990000','#B45F06','#BF9000','#38761D','#134F5C','#1155CC','#0B5394','#351C75','#741B47',
      '#5B0F00','#660000','#783F04','#7F6000','#274E13','#0C343D','#1C4587','#073763','#20124D','#4C1130',
    ],
    colorsBordered:[
      '#EFEFEF','#F3F3F3','#FFFFFF','#FFFF00','#FCE5CD','#FFF2CC','#D9EAD3'
    ],
    colorsBlackSelect:[
      '#D9D9D9','#EFEFEF','#F3F3F3','#FFFFFF',
      '#FFFF00','#00FFFF',
      '#F4CCCC','#FCE5CD','#FFF2CC','#D9EAD3','#D0E0E3','#C9DAF8','#CFE2F3','#D9D2E9','#EAD1DC',
      '#FFE599',
    ]
  }},
  watch: { 
    value: function(newVal, oldVal) {
      this.color = newVal;
    }
  },
  methods: {
    setColor (color) {
      this.color=color;
      this.$emit('input', this.color)
      this.isOpenColorPanel=false;
    },
    openPanel(){
      this.isOpenColorPanel=true;
    },
    closePanel(){
      this.isOpenColorPanel=false;
    },
  },
  mounted(){
    // setTimeout(()=>{
    //   this.frameMenuOn=true;
    //   this.newInterval();
    // },1500);
  },
}
</script>
