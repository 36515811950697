
import Vue from 'vue'
import VueRouter from 'vue-router'
import Create from '../routes/Create.vue'
import MyCard from '../routes/MyCard.vue'
import Card from '../routes/Card.vue'
import Visitcard from '../routes/Visitcard.vue'

import p404 from '../routes/404.vue'
import find from '../routes/find.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Create',
    component: Create
  },
  {
    path: '/edit/:id',
    name: 'Edit',
    component: Create
  },
  {
    path: '/card/:id/:code',
    name: 'Card',
    component: Card
  },
  {
    path: '/visitcard/:code',
    name: 'Visitcard',
    component: Visitcard
  },
  {
    path: '/mycard/:id',
    name: 'MyCard',
    component: MyCard
  },
  {
    path: '/404',
    name: '404',
    component: p404
  },
  {
    path: '/:id',
    name: 'find',
    component: find
  },
  { path: "*",name: '404', component: p404 }
]

const router = new VueRouter({
  mode: 'history',
  base: "/",//demo30/
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    return { x: 0, y: 0 }
  },
  
})
router.beforeEach((to, from, next) => {
  if(to.path=='/' || to.path.includes('/mycard/')){// 
    document.querySelector('body').classList.add('main');
    document.querySelector('body').classList.remove('p404');
  }else if(to.path=='/404' || to.name == '404'){
    document.querySelector('body').classList.add('p404');
    document.querySelector('body').classList.remove('main');
  }else{
    document.querySelector('body').classList.remove('p404');
    document.querySelector('body').classList.remove('main');
  }
  
  next();
})

export default router
