<template>
  <div class="ps404">
    <div class="container">
      <div class="title">
        404
      </div>
      <div class="error">
        Страница не найдена
      </div>
      <div class="description">
        К сожалению, запрашиваемая вами страница была удалена или перемещена. Вы можете воспользоваться навигацией сайта или проверить введенный адрес в адресной строке.
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: '404',
  data(){return{
  }},
  components: {
  },
  beforeCreate : async function () {
    if(!this.$route.params.id) return;
    try{
      const result = await axios.get((process.env.NODE_ENV === "production" ? "." : "https://sc.vorobev.pro" ) + "/api/card/getOld/"+this.$route.params.id, {withCredentials: false});
      console.log(result);
      if(result.data.success) return this.$router.push('/visitcard/'+result.data.card.code);
    }catch(e){
      alert('Internal error');
    }
  },
  mounted : function () {
  },
  beforeDestroy:function () {
  },
  methods: {
  }
}

</script>