<template>
    <section class="create" id="create">
      <div class="container">
        <div class="recycle"></div>
        <div class="sTitle">НАЧНИТЕ БЕРЕЧЬ&nbsp;<br class="sM">ПРИРОДУ&nbsp;<br class="sD">УЖЕ СЕГОДНЯ!</div>
        <div class="pBox">
          <div class="description">Введите данные, которые должны быть<br>в вашей digital-визитке</div>
          <div class="raw">
            <div>
              <input :class="{input:true,'error':formError.fullname}"  v-on:input="onInputFullname" type="text" placeholder="Имя и фамилия*" v-model="form.fullname">
              <input :class="{input:true,'error':formError.org}"  v-on:input="onInputOrg" type="text" placeholder="Название компании*" v-model="form.org">
              <input :class="{input:true,'error':formError.position}" type="text" placeholder="Должность" v-model="form.position">
              <input :class="{input:true,'error':formError.phone}"  v-on:input="onInputPhone" type="text" ref="phoneField" placeholder="Мобильный телефон*" v-model="form.phone">
              <input :class="{input:true,'error':formError.email}"  v-on:input="onInputEmail" type="text" placeholder="Почта" v-model="form.email">
            </div>
            <div>
              <div class="input">
                <input type="file" @change="previewFiles"  ref="uploadFile" accept="image/*">
                <div class="title">{{form.fileTitle}}</div>
                <div class="tools">
                  <div class="upload" v-on:click="initUpload"></div>
                  <div class="crop" v-on:click="initCrop" v-if="fileSelected"></div>
                  <div class="delete" v-on:click="initDelete" v-if="fileSelected"></div>
                </div>
              </div>
              <color-selector title="Цвет визитки" v-bind:value="form.bgcolor" v-on:input="form.bgcolor = $event" />
              <color-selector title="Цвет текста" v-bind:value="form.textcolor" v-on:input="form.textcolor = $event" />
              <input :class="{input:true,'error':formError.site}" type="text" ref="siteField" placeholder="Ссылка на сайт компании" v-model="form.site">
              <input :class="{input:true,'error':formError.social}" v-on:input="onInputTg" type="text" placeholder="Telegram" v-model="form.social">
            </div>
          </div>
          <a class="button full" v-on:click="send" v-if="!form.editForm">Создать Digital-визитку</a>
          <a class="button full" v-on:click="send" v-if="form.editForm">Сохранить изменения</a>
          <div class="buttonDescription">Нажимая на кнопку, вы даете согласие на обработку персональных&nbsp;<br class="sD">данных<br class="sM">и соглашаетесь c <a href="./files/user-agreement.docx">пользовательским соглашением</a></div>
        </div>
      </div>
      <div class="modalCrop" v-if="openCropForm" >
        <div class="shadow" v-on:click="closeCrop"></div>
        <div class="modalContainer">
          <div class="header">
            Обрезать фото
            <div class="close" v-on:click="closeCrop"></div>
          </div>
          <vue-cropper
            class="cropper"
            ref="cropper"
            :guides="false"
            :view-mode="2"
            drag-mode="crop"
            :auto-crop-area="0.8"
            :background="false"
            :rotatable="false"
            :src="cropperSrc"
            alt="Source Image"
            :img-style="{ 'width': '100%'}"
            :aspect-ratio="1"
          ></vue-cropper>
          <a class="button" v-on:click="saveCrop">СОХРАНИТЬ</a>
        </div>
      </div>
      <div class="modalAlert" v-if="openModalAlert" >
        <div class="shadow" v-on:click="closeAlert"></div>
        <div class="modalContainer">
          <div class="header">
            Больше возможностей
            <div class="close" v-on:click="closeAlert"></div>
          </div>
          <div class="modalText">Мы признательны, что ваша компания сотрудничает с SUSTAINABLE CARD! Лимит бесплатного использования сервиса — 10 визиток и, он уже использован. Напишите, пожалуйста, нам на почту для дальнейшего корпоративного сотрудничества</div>
          <a class="button" target="_blank" href="mailto:info@sustainable-card.com" v-on:click="closeAlert">Написать</a>

        </div>
      </div>
      <div class="loader" v-if="loader">
        <div class="box">
          <div class="i1"></div>
          <div class="i2"></div>
          <div class="i3"></div>
        </div>
      </div>
    </section>
</template>
<script>
import VueCropper from "vue-cropperjs";
import Inputmask from 'inputmask';
import ColorSelector from '@/components/colorselector.vue';
import axios from 'axios';
axios.defaults.withCredentials = false;

function validateEmail(email) {
  if(email=='') return true;
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validateTelegram(tg) {
  if(tg=='') return true;
  const re = /^((https\:\/\/t\.me\/)|(@)){0,1}[0-9a-zA-Z\_]+$/;
  return re.test(String(tg).toLowerCase());
}

export default {
  components: {
    VueCropper,
    'color-selector': ColorSelector,
  },
  name: 'Create',
  data(){return {
    editForm: false,
    loader: false,
    form:{
      _token:'',
      fullname:'',
      phone: '',
      org: '',
      position:'',
      site:'',
      bgcolor:'',
      textcolor:'',
      social: '',
      email:'',
      fileTitle: 'Загрузить фото',
      originalFile: '',
      croppedFile: '',
      originalFile_changed: false,
      croppedFile_changed: false,
    },
    formError:{
      fullname:false,
      phone: false,
      org: false,
      position:false,
      site:false,
      bgcolor:false,
      textcolor:false,
      social: false,
      email:false,
      fileTitle: false,
      originalFile: false,
      croppedFile: false,
    },
    file: null,
    fileSelected: false,
    defFileTitle: 'Загрузить фото',
    openCropForm: false,
    cropperSrc: '',
    cropCoordinates:null,
    openModalAlert: false,
  }},
  beforeMount : async function () {
    if(!this.$route.params.id) return;
    if(this.$route.params.id.length<26) return this.$router.push('/404');
    try{
      this.loader=true;
      const result = await axios.get((process.env.NODE_ENV === "production" ? "." : "https://sc.vorobev.pro" ) + "/api/card/getBySK/"+this.$route.params.id+'?loadPhoto', {withCredentials: false});
      if(!result.data.success) return this.$router.push('/404');
      // console.log(result);
      //this.card = result.data.card;
      this.form._token=this.$route.params.id || '';
      this.form.editForm=true;
      this.form.fullname=result.data.card.name || '';
      this.form.phone=result.data.card.phone || '';
      this.form.org=result.data.card.org || '';
      this.form.position=result.data.card.position || '';
      this.form.site=result.data.card.site || '';
      this.form.bgcolor=result.data.card.color_bg || '';
      this.form.textcolor=result.data.card.color_text || '';
      this.form.social=result.data.card.facebook || '';
      this.form.email=result.data.card.email || '';
      if(result.data.card.photo_file_title && result.data.card.photo_file_title!=''){
        this.form.fileTitle=result.data.card.photo_file_title;
      }
      if(result.data.card.photo_original && result.data.card.photo_original!=''){
        this.form.originalFile = result.data.card.photo_original;
        const byteCharacters = atob(result.data.card.photo_original);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        this.file = new Blob([byteArray], {type: 'image/png'});
        this.fileSelected=true;
      }
      this.loader=false;
      //console.log(result.data.card.photo_original);
    }catch(e){
      this.loader=false;
      alert('Internal error');
      // return this.$router.push('/404');
    }
  },
  methods: {
    initUpload:function ($event){
      this.$refs.uploadFile.click();
    },
    previewFiles:function (event) {
      if (typeof FileReader === "function") {
        let file = event.target.files[0];
        this.file=file;
        this.form.fileTitle=file.name;
        this.fileSelected=true;
        this.form.croppedFile = '';
        this.cropCoordinates = null;
        this.cropperSrc='';
        this.form.originalFile_changed=true;
        const reader = new FileReader();
        reader.onload = event => {
          this.form.originalFile = event.target.result;
        };
        reader.readAsDataURL(file);
        this.initCrop(event);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    initCrop:function ($event){
      if (typeof FileReader === "function") {
        this.openCropForm=true;
        const reader = new FileReader();
        reader.onload = event => {
          if(this.cropperSrc==''){
            this.cropperSrc = event.target.result;
            this.$refs.cropper.replace(event.target.result);
          }else if(this.cropCoordinates!==null){
            this.$refs.cropper.setCropBoxData({...this.cropCoordinates});
            setTimeout(()=>{
              this.$refs.cropper.setCropBoxData({...this.cropCoordinates});
            },100)
            setTimeout(()=>{
              this.$refs.cropper.setCropBoxData({...this.cropCoordinates});
            },250)
          }
        };
        reader.readAsDataURL(this.file);
        // reader.readAsArrayBuffer();
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    saveCrop:function ($event){
      this.form.croppedFile = this.$refs.cropper.getCroppedCanvas().toDataURL();
      let cropperData=this.$refs.cropper.getCropBoxData();
      this.cropCoordinates=cropperData;
      this.form.croppedFile_changed=true;
      this.openCropForm=false;
    },
    closeCrop:function($event){
      this.openCropForm=false;
    },
    closeAlert:function($event){
      this.openModalAlert=false;
    },
    initDelete:function ($event){
      this.$refs.uploadFile.value = '';
      this.form.fileTitle = this.defFileTitle;
      this.fileSelected=false;
      this.file=null;
      this.form.croppedFile = '';
      this.cropperSrc='';
      this.form.originalFile='';
      this.form.originalFile_changed=true;
    },
    onInput:function () {
      //
    },
    onInputFullname:function () {
      if(this.form.fullname=='') {this.formError.fullname=true;}
      else this.formError.fullname=false;
    },
    onInputPhone:function () {
      if(this.form.phone.length<4) {this.formError.phone=true;}
      else this.formError.phone=false;
    },
    onInputOrg:function () {
      if(this.form.org=='') {this.formError.org=true;}
      else this.formError.org=false;
    },
    onInputEmail:function () {
      if(!validateEmail(this.form.email)) {this.formError.email=true;}
      else this.formError.email=false;
    },
    onInputTg:function () {
      if(!validateTelegram(this.form.social)) {this.formError.social=true;}
      else this.formError.social=false;
    },
    send:async function(){
      let error = false;
      if(this.form.fullname=='') {error=true; this.formError.fullname=true;} 
      if(this.form.phone.length<10) {error=true; this.formError.phone=true;}  
      if(this.form.org=='') {error=true; this.formError.org=true;}  
      if(!validateEmail(this.form.email)) {error=true; this.formError.email=true;} 
      if(!validateTelegram(this.form.social)) {error=true; this.formError.social=true;}  
      if(error)return;

      let formData = new FormData();
      let url = "/api/card/add";
      if(this.form._token!=''){
        formData.append("_token", this.form._token);
        url = "/api/card/save";
        formData.append("originalFile_changed", this.form.originalFile_changed);
        formData.append("croppedFile_changed", this.form.croppedFile_changed);
        if(this.form.originalFile_changed){
          if(this.form.originalFile!=''){
            formData.append("originalFile", this.form.originalFile);
            formData.append("fileTitle", this.form.fileTitle);
          }
        }
        if(this.form.croppedFile_changed){
          if(this.form.croppedFile!='')formData.append("croppedFile", this.form.croppedFile);
        }
      }else{
        if(this.form.originalFile!=''){
          formData.append("originalFile", this.form.originalFile);
          formData.append("fileTitle", this.form.fileTitle);
        }
        if(this.form.croppedFile!='')formData.append("croppedFile", this.form.croppedFile);
      }
      formData.append("fullname", this.form.fullname);
      formData.append("phone", this.form.phone);
      formData.append("org", this.form.org);
      formData.append("position", this.form.position);
      formData.append("site", this.form.site);
      formData.append("color_bg", this.form.bgcolor);
      formData.append("color_text", this.form.textcolor);
      formData.append("facebook", this.form.social);
      formData.append("email", this.form.email);
      this.loader=true;
      try{
        const result = await axios.post((process.env.NODE_ENV === "production" ? "." : "https://sc.vorobev.pro" ) + url, formData, {withCredentials: false});
        this.loader=false;
        if(result.data.success){
          this.$router.push('/mycard/'+result.data.secretKey+'#mycard');
        }else if(result.data.code && result.data.code=='total'){
          this.openModalAlert=true;
        }else{
          alert('Internal error');
        }
      }catch(e){
        this.loader=false;
        alert('Internal error');
      }
    }
  },
  mounted(){
    var im = new Inputmask("+9{1,20}", {
      placeholder: "",
      showMaskOnHover: false,
      autoUnmask: true,
      definitions: {
        '9': {
          validator: "[0-9]",
        }
      }
    });
    im.mask(this.$refs.phoneField);
  },
}
</script>
