<template>
  <div
    class="form-wrapper"
    :class="{
      'form-wrapper_submited': isFormSubmited,
      'form-wrapper_opened': isFormOpened,
    }"
  >
    <form class="form" v-if="!isFormSubmited" v-on:submit="onFormSubmit">
      <div class="form__close">
        <button type="button" v-on:click="onClose">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
              fill="#808080"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
              fill="#808080"
            />
          </svg>
        </button>
      </div>
      <div class="form__inputs">
        <input
          type="text"
          class="form__input"
          :class="{ form__input_error: error.name }"
          placeholder="Имя*"
          name="name"
          ref="nameField"
          v-on:input="onInputName"
        />
        <input
          type="text"
          class="form__input"
          :class="{ form__input_error: error.company }"
          placeholder="Компания*"
          name="company"
          v-model="form.company"
          v-on:input="onInputCompany"
        />
        <input
          type="text"
          class="form__input"
          :class="{ form__input_error: error.stuff }"
          placeholder="Количество сотрудников*"
          name="stuff"
          ref="stuffField"
          v-on:input="onInputStuff"
        />
        <input
          type="text"
          class="form__input form__input_half"
          :class="{ form__input_error: error.phone }"
          placeholder="Телефон*"
          name="phone"
          ref="phoneField"
          v-model="form.phone"
          v-on:input="onInputPhone"
        />
        <input
          type="text"
          class="form__input form__input_half"
          :class="{ form__input_error: error.telegram }"
          placeholder="Telegram"
          name="telegram"
          v-model="form.telegram"
          v-on:input="onInputTg"
        />
        <input
          type="email"
          class="form__input"
          :class="{ form__input_error: error.email }"
          placeholder="E-mail*"
          name="email"
          v-model="form.email"
          v-on:input="onInputEmail"
        />
        <textarea
          class="form__input form__textarea"
          placeholder="Комментарий"
          name="comment"
          v-model="form.comment"
        ></textarea>
        <div
          class="form__checkbox"
          :class="{ form__checkbox_error: error.checkbox }"
        >
          <label>
            <input
              type="checkbox"
              name="agree"
              v-on:input="onCheckboxChange"
              v-model="form.checkbox"
            />
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="13"
                viewBox="0 0 18 13"
                fill="none"
              >
                <path
                  d="M6.00012 10.1998L2.50012 6.69982C2.11012 6.30982 1.49012 6.30982 1.10012 6.69982C0.710117 7.08982 0.710117 7.70982 1.10012 8.09982L5.29012 12.2898C5.68012 12.6798 6.31012 12.6798 6.70012 12.2898L17.3001 1.69982C17.6901 1.30982 17.6901 0.689824 17.3001 0.299824C16.9101 -0.0901758 16.2901 -0.0901758 15.9001 0.299824L6.00012 10.1998Z"
                  fill="white"
                />
              </svg>
            </span>
            <p class="form__checkbox-text">
              Я соглашаюсь на обработку персональных данных и
              <a href="./files/user-agreement.docx"
                >пользовательское соглашение</a
              >
            </p>
          </label>
        </div>
      </div>
      <div class="form__submit">
        <button type="submit" class="btn btn_violet">Отправить</button>
      </div>
    </form>
    <div class="form-submited" v-else>
      <div class="form__close">
        <p>{{ formTitle }}</p>
        <button type="button" v-on:click="onClose">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.46967 3.46967C3.76256 3.17678 4.23744 3.17678 4.53033 3.46967L20.5303 19.4697C20.8232 19.7626 20.8232 20.2374 20.5303 20.5303C20.2374 20.8232 19.7626 20.8232 19.4697 20.5303L3.46967 4.53033C3.17678 4.23744 3.17678 3.76256 3.46967 3.46967Z"
              fill="#808080"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.5303 3.46967C20.8232 3.76256 20.8232 4.23744 20.5303 4.53033L4.53033 20.5303C4.23744 20.8232 3.76256 20.8232 3.46967 20.5303C3.17678 20.2374 3.17678 19.7626 3.46967 19.4697L19.4697 3.46967C19.7626 3.17678 20.2374 3.17678 20.5303 3.46967Z"
              fill="#808080"
            />
          </svg>
        </button>
      </div>
      <p class="form__success-text">
        {{ formMessage }}
      </p>
      <div class="form__to-main">
        <button class="btn btn_violet" v-on:click="onClose">На главную</button>
      </div>
    </div>
  </div>
</template>
<script>
import Inputmask from "inputmask";

function validateEmail(email) {
  if (email == "") return false;
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validateTelegram(tg) {
  if (tg == "") return true;
  const re = /^((https\:\/\/t\.me\/)|(@)){0,1}[0-9a-zA-Z\_]+$/;
  return re.test(String(tg).toLowerCase());
}

export default {
  name: "custom-form",
  data() {
    return {
      isFormSubmited: false,
      isFormOpened: false,
      successMessage:
        "Мы получили вашу заявку и в ближайшее время свяжемся с вами",
      successTitle: "Спасибо!",
      errorMessage: "Произошла ошибка, попробуйте отправить форму позже",
      errorTitle: "Ошибка!",
      formMessage: 'Мы получили вашу заявку и в ближайшее время свяжемся с вами',
      formTitle: 'Спасибо!',
      form: {
        name: "",
        company: "",
        stuff: "",
        phone: "",
        telegram: "",
        email: "",
        comment: "",
        checkbox: false,
      },
      error: {
        name: false,
        company: false,
        stuff: false,
        phone: false,
        email: false,
        checkbox: false,
      },
    };
  },
  methods: {
    onInputName: function () {
      if (
        this.$refs.nameField.value.includes("0") ||
        this.$refs.nameField.value.includes("1") ||
        this.$refs.nameField.value.includes("2") ||
        this.$refs.nameField.value.includes("3") ||
        this.$refs.nameField.value.includes("4") ||
        this.$refs.nameField.value.includes("5") ||
        this.$refs.nameField.value.includes("6") ||
        this.$refs.nameField.value.includes("7") ||
        this.$refs.nameField.value.includes("8") ||
        this.$refs.nameField.value.includes("9")
      ) {
      } else {
        this.form.name = this.$refs.nameField.value;
      }
      this.$refs.nameField.value = this.form.name;
      if (this.form.name.length < 2) {
        this.error.name = true;
      } else this.error.name = false;
    },
    onInputCompany: function () {
      if (this.form.company.length < 2) {
        this.error.company = true;
      } else this.error.company = false;
    },
    onInputStuff: function () {
      if (
        !isNaN(this.$refs.stuffField.value) &&
        !this.$refs.stuffField.value.includes(" ")
      ) {
        this.form.stuff = this.$refs.stuffField.value;
      }
      this.$refs.stuffField.value = this.form.stuff;
      if (this.form.stuff == "") {
        this.error.stuff = true;
      } else this.error.stuff = false;
    },
    onInputPhone: function () {
      if (this.form.phone.length < 4) {
        this.error.phone = true;
      } else this.error.phone = false;
    },
    onInputEmail: function () {
      if (!validateEmail(this.form.email)) {
        this.error.email = true;
      } else this.error.email = false;
    },
    onInputTg: function () {
      if (!validateTelegram(this.form.telegram)) {
        this.error.telegram = true;
      } else this.error.telegram = false;
    },
    onCheckboxChange: function () {
      this.error.checkbox = false;
    },
    sendFormData: function () {
      const url = process.env.VUE_APP_BASE_URL;
      if (!url) {
        console.log("Добавьте env-пременную VUE_APP_BASE_URL");
        return;
      }
      const data = {
        name: this.form.name,
        org: this.form.company,
        employee_count: +this.form.stuff,
        phone: this.form.phone,
        telegram: this.form.telegram,
        email: this.form.email,
        comment: this.form.comment,
      };
      return fetch(`${url}/api/request/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    },
    onFormSubmit: function (e) {
      e.preventDefault();
      this.onInputName();
      this.onInputCompany();
      this.onInputStuff();
      this.onInputPhone();
      this.onInputTg();
      this.onInputEmail();
      if (!this.form.checkbox) {
        this.error.checkbox = true;
      }
      if (
        this.error.name ||
        this.error.company ||
        this.error.stuff ||
        this.error.phone ||
        this.error.telegram ||
        this.error.email ||
        this.error.checkbox
      ) {
        return;
      }
      this.sendFormData()
        .then((res) => {
          if (res.ok) {
            this.formTitle = this.successTitle;
            this.formMessage = this.successMessage;
            this.form.name = "";
            this.form.company = "";
            this.form.stuff = "";
            this.form.phone = "";
            this.form.telegram = "";
            this.form.email = "";
            this.form.comment = "";
            this.form.checkbox = false;
            this.isFormOpened = true;
            this.isFormSubmited = true;
          } else {
            this.formTitle = this.errorTitle;
            this.formMessage = this.errorMessage;
            this.form.name = "";
            this.form.company = "";
            this.form.stuff = "";
            this.form.phone = "";
            this.form.telegram = "";
            this.form.email = "";
            this.form.comment = "";
            this.form.checkbox = false;
            this.isFormOpened = true;
            this.isFormSubmited = true;
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Internal error");
        });
    },
    onClose: function () {
      document.body.style.overflow = "";
      this.$el.classList.remove("form-wrapper_opened");
      this.isFormOpened = false;
      this.isFormSubmited = false;
    },
  },
  mounted() {
    var im = new Inputmask("+9{1,20}", {
      placeholder: "",
      showMaskOnHover: false,
      autoUnmask: true,
      definitions: {
        9: {
          validator: "[0-9]",
        },
      },
    });
    im.mask(this.$refs.phoneField);
    this.$refs.phoneField.setAttribute("has-mask", "");
  },
  updated() {
    if (
      this.$refs.phoneField &&
      !this.$refs.phoneField.hasAttribute("has-mask")
    ) {
      var im = new Inputmask("+9{1,20}", {
        placeholder: "",
        showMaskOnHover: false,
        autoUnmask: true,
        definitions: {
          9: {
            validator: "[0-9]",
          },
        },
      });
      im.mask(this.$refs.phoneField);
      this.$refs.phoneField.setAttribute("has-mask", "");
    }
  },
};
</script>