<template>
  <div id="app">
    <router-view />
    <custom-form />
  </div>
</template>
<script>
import Form from "@/components/form.vue";
export default {
  name: "app",
  components: {
    "custom-form": Form,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    if (process.env.VUE_APP_TARGET === "prod") {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML = `(function (m, e, t, r, i, k, a) {
        m[i] =
          m[i] ||
          function () {
            (m[i].a = m[i].a || []).push(arguments);
          };
        m[i].l = 1 * new Date();
        for (var j = 0; j < document.scripts.length; j++) {
          if (document.scripts[j].src === r) {
            return;
          }
        }
        (k = e.createElement(t)),
          (a = e.getElementsByTagName(t)[0]),
          (k.async = 1),
          (k.src = r),
          a.parentNode.insertBefore(k, a);
      })(
        window,
        document,
        "script",
        "https://mc.yandex.ru/metrika/tag.js",
        "ym"
      );

      ym(68559901, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });`;
      const noscript = document.createElement("noscript");
      noscript.innerHTML = `<div>
        <img
          src="https://mc.yandex.ru/watch/68559901"
          style="position: absolute; left: -9999px"
          alt=""/></div>`;
      document.querySelector("body").appendChild(script);
      document.querySelector("body").appendChild(noscript);
    }
  },
};
</script>