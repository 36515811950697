<template>
  <div></div>
</template>
<script>
import axios from 'axios';
axios.defaults.withCredentials = false;

export default {
  name: 'find',
  data(){return{
  }},
  components: {
  },
  beforeCreate : async function () {
    if(!this.$route.params.id) return;
    try{
      const result = await axios.get((process.env.NODE_ENV === "production" ? "." : "https://sc.vorobev.pro" ) + "/api/card/getOld/"+this.$route.params.id, {withCredentials: false});
      if(result.data.success) return this.$router.push('/visitcard/'+result.data.card.code);
      else this.$router.push('/404');
    }catch(e){
      this.$router.push('/404');
    }
  },
  mounted : function () {
  },
  beforeDestroy:function () {
  },
  methods: {
  }
}

</script>